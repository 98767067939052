import React from 'react';
import useFetch from './hooks/useFetch';
import ListingMap from './components/Map';
import ListingAccordion from './components/Accordion';

import './App.scss';
import 'bootstrap';

function App() {
  const posts = useFetch('/posts?per_page=100&tags=7456');

  return (
    <div className="container-fluid wrap">
      <div className="row align-items-center justify-content-between mb-2">
        <div className="col">
          <ul
            className="nav nav-pills nav-pills nav-fill"
            id="tabControls"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="map-tab"
                data-toggle="tab"
                href="#map"
                role="tab"
                aria-controls="map"
                aria-selected="true"
              >
                Map View
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="list-tab"
                data-toggle="tab"
                href="#list"
                role="tab"
                aria-controls="list"
                aria-selected="false"
              >
                List View
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="map"
              role="tabpanel"
              aria-labelledby="map-tab"
            >
              <ListingMap data={posts} />
            </div>
            <div
              className="tab-pane fade"
              id="list"
              role="tabpanel"
              aria-labelledby="list-tab"
            >
              <ListingAccordion data={posts} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
