import React from "react";
import useFetch from "../hooks/useFetch";
import { strDecode } from "../helpers";

const ListingAccordion = ({ data }) => {
  const categories = useFetch(`/categories?per_page=100`);

  const buildList = () => {
    const listing = []; // {name: '', id: '', posts: []}

    categories.forEach((category) => {
      let categoryPosts = data.filter((post) => {
        return post.categories.includes(category.id);
      });

      // remove older posts for the same location
      categoryPosts = Array.from(
        new Set(
          categoryPosts.map(
            (a) => a.metadata.photo_place_name && a.metadata.photo_place_name[0]
          )
        )
      ).map((id) => {
        return categoryPosts.find(
          (a) =>
            a.metadata.photo_place_name && a.metadata.photo_place_name[0] === id
        );
      });

      if (categoryPosts.length > 0) {
        const listingCategory = {
          name: category.name,
          id: category.id,
          posts: categoryPosts,
        };

        listing.push(listingCategory);
      }
    });

    return listing;
  };

  return (
    <div className="accordion" id="listingAccordion">
      {console.log(buildList()) ||
        buildList().map((cat, index) => (
          <div className="card" key={cat.id}>
            <h4
              className="card-header"
              data-toggle="collapse"
              data-target={`#d${cat.id}`}
              role="button"
              aria-expanded="false"
              aria-controls={`${cat.id}`}
            >
              {strDecode(cat.name)}
            </h4>
            <div
              id={`d${cat.id}`}
              className={`collapse ${index === 0 ? "show" : ""}`}
              aria-labelledby="headingOne"
              data-parent="#listingAccordion"
            >
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row border-bottom">
                    <div className="col">
                      <h6>Name</h6>
                    </div>
                    <div className="col">
                      <h6>Pledge</h6>
                    </div>
                  </div>
                  {cat.posts.map(
                    (post) =>
                      post && (
                        <div
                          className="row pt-2 pb-2 mb-2 border-bottom"
                          key={post.id}
                        >
                          <div className="col">
                            <h5 className="mb-0">
                              {strDecode(post.metadata.photo_place_name)}
                            </h5>
                            <div>{post.metadata.formatted_address}</div>
                            <div>
                              <a href={`tel:${post.metadata.phone_number}`}>
                                {post.metadata.phone_number}
                              </a>
                            </div>
                          </div>
                          <div className="col">
                            <div>{strDecode(post.title.rendered)}</div>
                            <div>
                              <a
                                href={`${post.link}`}
                                target="_parent"
                                rel="noopener noreferrer"
                              >
                                View post
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ListingAccordion;
