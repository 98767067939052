import React, { useState, useRef } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { strDecode } from "../helpers";
import { TILE_URL } from "../constants";

import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapMarker = ({ item = {} }) => {
  const { location_lat, location_lng } = item.metadata;

  let latLng = [parseFloat(location_lat), parseFloat(location_lng)];

  if (latLng.includes(NaN)) {
    latLng = [0, 0];
  }

  return (
    <Marker position={latLng}>
      <Popup>
        <div className="popup-title mb-2 font-bold">
          <h5>{strDecode(item.metadata.photo_place_name)}</h5>
        </div>
        <div className="popup-description mb-2">{item.title.rendered}</div>
        <div className="popup-description mb-2">
          <a href={`${item.link}`} target="_blank" rel="noopener noreferrer">
            View post
          </a>
        </div>
        <div>{item.metadata.formatted_address}</div>
        <div>
          <a href={`tel:${item.metadata.phone_number}`}>
            {item.metadata.phone_number}
          </a>
        </div>
      </Popup>
    </Marker>
  );
};

const ListingMap = ({ data }) => {
  const ref = useRef();
  const [editing, setEditing] = useState(false);
  useOnClickOutside(ref, () => setEditing(false));

  return (
    <div className="map-container" ref={ref}>
      {!editing && (
        <div className="overlay" onClick={() => setEditing(true)}>
          Click to interact with the map
        </div>
      )}
      <Map center={[33.457, -112.05]} zoom={11} style={{ height: 600 }}>
        <TileLayer
          url={TILE_URL}
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        />
        {data.map((item) => (
          <MapMarker item={item} key={item.id} />
        ))}
      </Map>
    </div>
  );
};

export default ListingMap;
