import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../constants';

function useFetch(url) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadData() {
      const response = await fetch(`${API_BASE_URL}${url}`);
      if (!response.ok) {
        return;
      }

      const responseJSON = await response.json();
      setData(responseJSON);
    }

    loadData();
  }, [url]);
  return data;
}

export default useFetch;
